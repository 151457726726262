import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import NDTF from "../components/ndtf"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  // const instaFeed = data.allInstaNode.nodes
  // const instaSingle = data.allInstaNode.nodes[0].original
  const testimonials = data.allContentfulTestItem.edges
  const t = data.allContentfulHomePage.nodes[0]
  const title = t.title
  const desc = t.seoDescription
  const intro = t.intro.childMarkdownRemark.html
  const articles = data.allContentfulServicesItem.nodes

  return (
    <Layout>
      <SEO title={title} description={desc} />
      <h1 className="flex flex-col center text-center px-6">{title}</h1>

      {/* <img
        src={instaSingle}
        alt="doggie"
        className="px-6 py-6 block sm:hidden md:hidden lg:hidden xl:hidden"
      /> */}

      <div className="px-6 py-12 hidden sm:grid md:grid lg:grid xl:grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-4 home-grid-i">
        {/* {instaFeed.map(img => (
          <div key={img.id}>
            <img src={img.original} alt="" />
          </div>
        ))} */}
      </div>
      {/* Intro  */}
      <div className="max-w-md mx-auto px-6">
        <NDTF className=" " />
      </div>
      <div
        className="px-6 max-w-6xl mx-auto text-justify"
        dangerouslySetInnerHTML={{
          __html: intro,
        }}
      />

      {/* TESTIMONIALS Normal*/}
      {testimonials.map(test => (
        <div
          key={test.node.id}
          className="my-6 p-6 bg-white md:flex rounded-lg shadow-light max-w-6xl mx-6 xl:mx-auto"
        >
          <img
            src={test.node.imageUrl}
            alt={test.node.name}
            className="md:h-24 md:w-24 md:min-h-24 md:min-w-24 h-16 w-16 min-h-16 min-w-16 rounded-full shadow-lg mx-auto md:self-end md:mr-6 mb-4"
          />

          <div className="text-center md:text-left">
            <blockquote className="text-black text-sm">
              <span className="first">“</span> {test.node.article.article}
              <span className="last">”</span>
            </blockquote>
            <cite className="text-black">{test.node.name}</cite>
          </div>
        </div>
      ))}

      {/* BLOG */}
      <div className="px-6 blog-grid home-grid max-w-6xl mx-auto">
        {data.allContentfulBlogPost.edges.map(edge => {
          return (
            <div key={edge.node.id} className="my-4">
              {edge.node.featuredImage && (
                <div
                  className="posts py-4 px-2 shadow-light rounded-lg"
                  style={{
                    background: `url('${edge.node.featuredImage.fluid.src}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                  }}
                >
                  <div className="posts">
                    <h3 className="text-left">{edge.node.title}</h3>
                    <p>{edge.node.excerpt.childMarkdownRemark.excerpt}</p>
                    <p className="text-sm date">{edge.node.publishedDate}</p>
                    <button className="button shadow-xl">
                      <Link
                        to={`/blog/${edge.node.slug}/`}
                        title={`Read More on ${edge.node.slug}`}
                      >
                        Read More
                      </Link>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>

      {/* Service Launch */}
      {articles.map(article => (
        <div className="px-6 min-h-full" key={article.id}>
          <div
            className="max-w-6xl mx-auto my-6 p-6 bg-white md:flex rounded-lg shadow-light"
            title={article.name}
          >
            <img
              src={article.image.file.url}
              alt={article.name}
              className="md:h-24 md:w-24 md:min-h-24 md:min-w-24 h-16 w-16 min-h-16 min-w-16 rounded-full shadow-lg mx-auto md:self-end mb-4"
            />

            <div className="text-center md:text-left md:ml-6">
              <blockquote className="text-black text-sm">
                <strong>{article.name} is one of our many services.</strong>
                <br />
                {article.article.childMarkdownRemark.excerpt}
                <br />
                <strong></strong>
              </blockquote>
              <Link to="/services/" className="posts">
                <button
                  className="button shadow-xl"
                  onClick={() => "/services/"}
                  title="See all of our services"
                >
                  See <strong>all</strong> of our services
                </button>

                {/* <cite className="text-black">
                  
                </cite> */}
              </Link>
            </div>
          </div>
        </div>
      ))}
      <div className="px-6 mx-auto">
        <div className="max-w-full xl:max-w-6xl bg-contact-btn text-black p-6 shadow-light md:mx-auto">
          <Link to="/contact/" title="Contact us">
            <h2 className="text-center text-xl">
              <span role="img">📞 </span>Contact us for more...
            </h2>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulTestItem(
      sort: { fields: updatedAt, order: ASC }
      limit: 1
      skip: 8
    ) {
      edges {
        node {
          id
          imageUrl
          name
          article {
            article
          }
          updatedAt
        }
      }
    }
    # allInstaNode(limit: 6, filter: { dimensions: { height: { gte: 1080 } } }) {
    #   nodes {
    #     original
    #     id
    #   }
    # }
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          id
          slug
          publishedDate(formatString: "Do MMMM, YYYY")
          featuredImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          excerpt {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
            }
          }
        }
      }
    }
    allContentfulHomePage {
      nodes {
        seoDescription
        title
        intro {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulServicesItem(
      limit: 1
      skip: 2
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        id
        name
        article {
          childMarkdownRemark {
            excerpt(pruneLength: 145)
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`

export default IndexPage

//   const instaFeed = data.allInstaNode.nodes
//   const instaSingle = data.allInstaNode.nodes[0].original

//       <img
//         src={instaSingle}
//         alt="doggie"
//         className="px-6 py-6 block sm:hidden md:hidden lg:hidden xl:hidden"
//       />

//          {instaFeed.map(img => (
//           <div key={img.id}>
//             <img src={img.original} alt="" />
//           </div>
//         ))}
//       </div>

//     allInstaNode(limit: 6, filter: { dimensions: { height: { gte: 1080 } } }) {
//       nodes {
//         original
//         id
//       }
//     }
